import React from "react";
import { connect } from "react-redux";
import { modelActions } from "../redux/actions";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { apiService } from "../services";
import { getter } from "./helper";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/importModel.css";
import ImportItem from "./controls/importItem";

class ModelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
      seletedItem: -1,
      importing_ifc: false,
      model: {},
    };
    this.handleSelectModel = this.handleSelectModel.bind(this);
    this.handleImportModel = this.handleImportModel.bind(this);

    window.ImportModel = this;
  }

  componentDidMount() {
    if (this.props.project.id) {
      this.getModels();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.models !== this.props.models) {
      this.displayModels();
    }
  }

  getModels() {
    if (this.props.models.length) {
      this.displayModels();
    }

    let project = this.props.project;
    if (project && project.modelIds) {
      this.props.getModels(project.modelIds);
    } else if (project) {
      this.props.getModels([]);
    }
  }

  displayModels() {
    let models = this.props.models.sort(function (a, b) {
      return Date.parse(b.creationDate) - Date.parse(a.creationDate);
    });
    this.setState({ models: models });
  }

  /**
   * Get a name of discipline specified by id.
   * @param {string} id
   * @returns {string} if a discipline specified by id exists, return it's name otherwise empty string.
   */
  getDisciplineName(id) {
    const discipline = this.props.disciplines.find((d) => d.id === id);
    return discipline ? discipline.abbreviation : "";
  }

  /**
   * Get a name of building specified by id.
   * @param {string} id
   * @returns {string} if a building specified by id exists, return it's name otherwise empty string.
   */
  getBuildingName(id) {
    const building = this.props.buildings.find((b) => b.id === id);
    return building ? building.abbreviation : "";
  }

  /**
   * Get a name of floor specified by id.
   * @param {string} id
   * @returns {string} if a floor specified by id exists, return it's name otherwise empty string.
   */
  getFloorName(id) {
    const floor = this.props.floors.find((f) => f.id === id);
    return floor ? floor.abbreviation : "";
  }

  handleSelectModel(e) {
    e.preventDefault();
    this.setState({ seletedItem: e.target.parentNode.rowIndex - 1 });
  }

  handleImportModel(e) {
    e.preventDefault();
    if (!!this.state.model.fileId) {
      this.setState({ importing_ifc: true });
      this.importModelInApp(this.state.model.fileId);
    }
  }

  registerIfcFileName(name) {
    window.RegisterIfcFileName(name);
  }

  async importModel(id) {
    const model = this.props.models.find((m) => m.fileId === id);
    await apiService
      .getIfcStream(model.fileUrl)
      .then((res) => {
        window.ImportIfcFile(res.data);
        this.setState({ importing_ifc: false });
      })
      .catch((err) => {
        console.log("Can't download the model", err);
        this.setState({ importing_ifc: false });
      });
  }

  importModelInApp(id) {
    const model = this.props.models.find((m) => m.fileId === id);
    const modelData = {
      id: id,
      name: model.name,
      version: model.version,
      extension: this.getFileExtension(model.fileName),
    };
    let data = {};
    data.token = getter.getTokenBearer();
    data.modelInfos = [modelData];
    window.ImportIfcFiles(JSON.stringify(data));
  }

  getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  completeImportModels() {
    this.setState({ importing_ifc: false });
    this.props.onGotoIssuePage();
  }

  handleChangeFileId = (fileId, fileName, index) => {
    const model = this.state.model;
    model.fileId = fileId;
    model.fileName = fileName;
    this.setState({ model });
  };

  handleDelete = () => {
    this.setState({ model: {} });
  };

  // renderTableData() {
  //     return this.state.models.map((model, index) => {
  //         const { name, disciplineMetaDataId, buildingMetaDataId, floorMetaDataId, version } = model;
  //         return (
  //             <tr className={this.state.seletedItem === index ? 'striped_row_selected' : 'striped_row'} key={index} onClick={this.handleSelectModel}>
  //                 <td width='40%'>{name}</td>
  //                 <td width='15%'>{this.getDisciplineName(disciplineMetaDataId)}</td>
  //                 <td width='15%'>{this.getBuildingName(buildingMetaDataId)}</td>
  //                 <td width='15%'>{this.getFloorName(floorMetaDataId)}</td>
  //                 <td width='15%'>{`V${version}`}</td>
  //             </tr>
  //         )
  //     })
  // }

  render() {
    const { model, importing_ifc } = this.state;
    const lang = window.isGerman ? de : en;

    return (
      <>
        <div className="importModel_container">
          <div className="importModel_table scrollbar">
            <ImportItem
              index={1}
              model={model}
              onChangeFileId={this.handleChangeFileId}
              onDelete={this.handleDelete}
            />
          </div>
          <div className="importModel_open">
            <button
              className="visoplan_button"
              onClick={this.handleImportModel}
              style={{ width: "180px" }}
            >
              {lang["ImportModel"]}
            </button>
          </div>
        </div>
        <Backdrop
          style={{
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
          open={importing_ifc}
        >
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    disciplines: state.metaData.disciplines,
    buildings: state.metaData.buildings,
    floors: state.metaData.floors,
    models: state.models.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModels: (ids) => {
      dispatch(modelActions.getModels(ids));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelList);
